@import "../../../style//vars.scss";
.header__menu__wrapper {
  padding-bottom: 65px;

  .header__menu__container {
    //
    padding: 10px 20px 20px 20px;

    //
    position: fixed;
    width: 100%;
    z-index: 100;

    display: flex;
    justify-content: space-between;
    height: 50px;
    background-color: $main_color;
    border-radius: 0px 0px 30px 30px;
    .header__menu__burger {
      display: block;
      padding-top: 5px;

      img {
        width: 25px;
      }
    }

    .header__menu__notification {
      .bg_notification {
        background-color: #4a65ec;
        padding: 7px 10px;
        border-radius: 60px;
      }
    }
  }
}
