@import url(../../style/vars.scss);

nav {
  .nav__container {
    background-color: #fff;
    bottom: 0;
    height: 65px;
    padding: 0;
    position: fixed;
    width: 100%;

    padding-top: 12px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);

    .nav__menu {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: auto;

      .nav__item {
        svg {
          width: 18px;
          height: 19px;
        }
        .title {
          font-size: 13px;
        }
        .active {
          color: blue;
        }
      }
    }
  }
}
