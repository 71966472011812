.post__wrapper__card {
  min-width: 240px;
  height: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 249, 249);
  border-radius: 20px;
  position: relative;
  margin-right: 20px;

  .post__img {
    img {
      display: block;
      width: 210px;
      height: 130px;
    }
  }
  .post__title {
    margin: 10px 10px;
    font-size: 12.5px;
    font-weight: 600;
    text-align: left;
    width: 85%;
  }

  .post__date {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 5px;
    position: absolute;
    color: #eb5757;
    border-radius: 10px;
    top: 20px;
    left: 20px;

    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0px;
    font-weight: 600;
    letter-spacing: 0.3;
  }

  .post__favorite {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 5px 5px 0px 5px;
    border-radius: 20px;
    top: 20px;
    right: 20px;
    position: absolute;
  }

  .post__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 82%;

    color: gray;
    .views {
      display: flex;

      .count {
        font-size: 11px;
        margin: 1px 0px 0px 4px;
      }
    }
    .tags {
      display: flex;
      font-size: 11px;
    }
  }
}
