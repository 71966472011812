.visit__wrapper {
  margin-top: 25px;
  text-align: right;

  form {
    button {
      min-width: 100px;
    }
  }
}
