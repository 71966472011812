@import url("../../../style/animations.scss");

.recovery__wrapper {
  padding: 55px 25px 25px 25px;
  text-align: start;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  //
  animation-name: appearAnimation;
  animation-duration: 1.5s;
  //

  .title__recovery {
    font-family: "Montserrat", sans-serif;
  }
  .descr__recovery {
    font-family: "Montserrat", sans-serif;
    margin-bottom: 15px;
    display: block;
  }
}
