@import "../../../style/vars.scss";
.banner__wrapper {
  padding: 20px;
  background-color: rgb(255, 244, 244);
  border-radius: 20px;
  margin: 0px 10px 20px 10px;

  .banner__container {
    display: flex;
    justify-content: space-between;
    img {
      opacity: 0.8;
      display: block;
      width: 100px;
      height: 100px;
    }
    .banner__info {
      .descr {
        color: rgb(0, 0, 0);
        text-align: start;
        font-size: 11px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        margin: 10px 0px 10px 0px;
      }

      .btn {
        width: 100px;

        button {
          font-size: 10px;
          font-family: Montserrat, serif;
          height: 30px;
        }
      }

      .title {
        text-align: start;
        color: $main_color;
        font-size: 19px;
        font-weight: 900;
      }
    }
  }
}
