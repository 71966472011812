@import "../../style/animations.scss";

.menu__wrapper {
  .user__info {
    text-align: start;
    min-height: 80px;

    .user_info_spin {
      padding-top: 15px;
    }
    .person {
      width: 30px;
      height: 30px;
    }
    .name {
      margin-top: 10px;
    }
  }

  .menu__list {
    margin-top: 30px;

    .menu__item {
      display: flex;
      margin-bottom: 25px;

      //ant class

      svg {
        color: rgba(118, 118, 118, 1);
        width: 20px;
        height: 20px;
        margin: 0px 2px;
      }

      img {
        width: 25px;
        height: 25px;
      }

      .title {
        margin-left: 10px;
        font-weight: 200;
        font-family: "Montserrat", sans-serif;
      }
    }
  }
}

.support_icon {
  margin-bottom: 35px;
}
