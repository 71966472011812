.notification_item {
  text-align: start;
  font-size: 13px;

  .time_item {
    font-weight: 500;
    font-size: 11.5px;
    vertical-align: middle;
  }
}
