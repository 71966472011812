@import url("../../style/animations.scss");

.layout__container {
  padding-bottom: 50px;

  .layout__app {
    padding: 70px 20px 25px 20px;

    height: 100%;

    font-family: Montserrat, sans-serif;
    animation-name: appearAnimation;
    animation-duration: 1.5s;
  }
}
