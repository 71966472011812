.register_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  width: 100%;

  .inp_icon {
    width: 30px;
  }

  .auth__sing_in {
    margin-top: 10px;
    text-align: start;
  }

  .auth__or {
    color: #9d9898;
    font-weight: 500;
  }
  .auth__google {
    margin: 15px 0px;
    line-height: 25px;
    box-shadow: 15px 0px 30px 0px rgba(211, 212, 226, 0.55);
    padding: 12px 35px 10px 35px;
    border-radius: 10px;
    span {
      font-size: 16px;
      font-weight: 300;
    }
    img {
      width: 27px;
      height: 27px;
    }
  }

  .auth__sign_up__container {
    .auth__sign_up {
      margin-right: 5px;
      color: black;
    }
  }
}

@media (max-height: 725px) {
  .register_wrapper {
    display: block;
  }
}
