@import url("../../style//animations.scss");
@import url("../../style/vars.scss");

.intro__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .intro__container {
    margin-top: auto;

    .spinner {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .aunt__intro {
      margin: 0px 5px 0px 5px;
      margin-top: auto;

      .info {
        font-family: "Montserrat", sans-serif;
        background-color: #5669ff;
        border-radius: 35px 35px 0px 0px;
        height: 155px;
        padding: 25px 5px 20px 5px;
        color: #fff;

        .title {
          font-size: 17px;
          font-weight: 500;
          margin-bottom: 10px;
        }
        .descr {
          font-size: 12.5px;
          font-weight: 300;
        }
      }

      img {
        margin: 0 auto;
      }
    }

    img {
      width: 65%;
      animation-name: appearAnimation;
      animation-duration: 4s;
    }
  }

  .background__intro {
    background: conic-gradient(from 180deg at 50% 50%, #b9dafb 0deg, #ecacad 95.99deg, #9895ee 206.25deg, #90a2f8 292.5deg, #c55492 360deg);
    z-index: -12;
    opacity: 2;
    filter: blur(90px);
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 500px) {
  .intro__wrapper {
    .intro__container {
      img {
        width: 40%;
      }
    }
  }
}
