.faq_item {
  font-family: "Montserrat", sans-serif;
  text-align: start;
}

.faq_text {
  text-align: start;

  section {
    margin-bottom: 6px;
  }

  .strong {
    font-weight: 600;
  }
}
