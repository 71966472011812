@import url("../../../style/animations.scss");

.auth__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;
  padding: 0px 30px 10px 30px;
  overflow-x: hidden;
  //
  animation-name: appearAnimation;
  animation-duration: 1.5s;
  //
  .inp_icon {
    width: 30px;
  }
  .auth__logo {
    width: 25%;
    min-width: 80px;
    min-height: 80px;
  }
  .auth__title {
    margin-top: 10px;
    text-align: center;
    font-weight: 700;
  }
  .auth__sing_in {
    margin-top: 10px;
    text-align: start;
  }

  .auth__or {
    color: #9d9898;
    font-weight: 500;
  }
  .auth__google {
    margin: 15px 0px;
    line-height: 25px;
    box-shadow: 15px 0px 30px 10px rgba(211, 212, 226, 0.55);
    padding: 12px 35px 10px 35px;
    border-radius: 10px;
    span {
      font-size: 16px;
      font-weight: 300;
    }
    img {
      width: 27px;
      height: 27px;
    }
  }

  .auth__sign_up__container {
    .auth__sign_up {
      margin-right: 5px;
      color: black;
    }
  }
  .auth__forgot {
    //ant
    strong {
      display: inline-block;
      margin-bottom: 23.9px;
    }
  }
}

@media (max-height: 700px) {
  .auth__wrapper {
    justify-content: space-between;
    padding-top: 20px;
  }
}

@media (max-width: 365px) {
  .auth__wrapper {
    .auth__google {
      margin: 15px 0px;
      line-height: 15px;

      padding: 10px 20px 10px 20px;
      span {
        font-size: 14px;
      }
    }
    .auth__title {
      font-size: 28px;
    }

    .auth__sing_in {
      font-size: 20px;
    }
  }
}

@media (max-width: 340px) {
  .auth__wrapper {
    .auth__forgot {
      font-size: 13px;
    }
  }
}
